<template>
  <div class="row">
    <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true" :color="color" loader="dots" />
    <div class="col-12 f13">
      <div class="card concard card-shadow">
        <div class="card-header white text-center">
          <img src="@/assets/logo.png" style="height: 100px" alt="..." />
          <div class="t1" style="margin: 5px"></div>
        </div>
        <Form @submit="onSubmitRegister" :validation-schema="register" id="kt_account_profile_details_form"
          ref="formContainer" class="" novalidate="novalidate">
          <div class="card-body mb-47 mt-2 white">
            <!--begin::Card body-->
            <div class="card-body">


              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6">คำนำหน้า</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field as="select" name="fn" class="form-control  form-control-solid" @change="checkFn($event)"
                    v-model="data_register.fn">
                    <option value="" selected disabled>โปรดเลือก</option>
                    <option value="นาย">นาย</option>
                    <option value="นาง">นาง</option>
                    <option value="นางสาว">นางสาว</option>
                    <option value="อื่นๆ">อื่นๆ</option>
                  </Field>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="fn" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row mt-1" v-if="fn_status">
                  <Field type="text" name="fn_other" class="form-control  form-control-solid" placeholder="คำนำหน้า"
                    v-model="data_register.fn_other" />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="fn_other" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6">ชื่อ</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field type="text" name="firstname" class="form-control  form-control-solid" placeholder="ชื่อ"
                    v-model="data_register.firstname" />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="firstname" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6">นามสกุล</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field type="text" name="lastname" class="form-control  form-control-solid" placeholder="นามสกุล"
                    v-model="data_register.lastname" />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="lastname" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6">เบอร์โทรศัพท์</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field type="text" name="tel" class="form-control  form-control-solid" placeholder="เบอร์โทรศัพท์"
                    v-model="data_register.tel" />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="tel" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6">ชื่อหน่วยงาน</label>

                <div class="col-lg-8 fv-row">
                  <Field type="text" name="agency_name" class="form-control form-control-solid"
                    placeholder="ชื่อ หน่วยงาน" v-model="data_register.agency_name" />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="agency_name" />
                    </div>
                  </div>
                </div>
              </div>
              <!-- {{ interes_value }}
              <br> -->

              <label class="col-lg-4 col-form-label required fw-bold fs-6">ความสนใจ?</label>
              <div class="row-12">
                <center>

                  <div class="form-check form-check-inline">
                    <Field type="checkbox" name="interes_type" class="form-check-input big-checkbox" id="interes_type"
                      value="interes_oa" v-model="interes_value" />
                    <label style="font-size: 25px;" class="form-check-label" for="interes_type">เปิดไลน์ OA</label>
                  </div>

                  <div class="form-check form-check-inline">
                    <Field type="checkbox" name="interes_type" class="form-check-input big-checkbox" id="interes_type"
                      value="interes_app" v-model="interes_value" />
                    <label style="font-size: 25px;" class="form-check-label" for="interes_type">สนใจระบบ</label>
                  </div>


                </center>
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="interes_type" />
                  </div>
                </div>
              </div>

              <div v-if="check_interes('interes_oa')">

                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6">ชื่อบัญชี Line Oa (ไม่เกิน 20
                  ตัวอักษร)</label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field type="text" name="line_official_name" class="form-control  form-control-solid" maxlength="20"
                    placeholder="ชื่อบัญชี Line Oa" v-model="data_register.line_official_name" />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="line_official_name" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->

                <label class="col-lg-4 col-form-label required fw-bold fs-6">ภาพ Logo หน่วยงาน</label>
                <div class="row mt-3">
                  <div class="col-lg-8 fv-row">
                    <div v-if="imageUrl.length !== 0" class="imagePreviewWrapper" style="padding: 15px 0px">
                      <div class="row">
                        <div style="position: relative" class="col-4" v-for="(item, index) in imageUrl" :key="index">
                          <div class="xx" @click="deleteImage(index)">
                            <span>x</span>
                          </div>
                          <img class="images" :src="item" />
                        </div>
                      </div>
                    </div>
                    <input type="file" id="getFilecamera" multiple="multiple" accept="image/jpg, image/jpeg ,image/png"
                      @input="pickFile" style="display: none" capture="camera" />
                    <input type="file" id="getFile" multiple="multiple" accept="image/jpg, image/jpeg, image/png"
                      @input="pickFile" style="display: none" />
                    <div class="d-flex justify-content-center">

                      <button type="button" @click="selectImage(2)" class="button-upload">
                        แนบรูป&nbsp;<img src="@/assets/picture.png" class="icon-ima" />
                      </button>

                      <Field type="hidden" name="imageUrl" v-model="imageUrl" />
                    </div>

                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="imageUrl" />
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <!--end::Card body-->

              <div v-if="check_interes('interes_app')">

                <div class="row-12">

                  <label class="col-lg-4 col-form-label required fw-bold fs-6">ต้องการเอกสาร</label>
                  <br>
                  <center>
                    <div class="form-check form-check-inline">
                      <Field type="checkbox" name="document" class="form-check-input big-checkbox" id="tor" value="TOR"
                        v-model="document_value" />
                      <label style="font-size: 25px;" class="form-check-label" for="TOR">TOR</label>
                    </div>

                    <div class="form-check form-check-inline">
                      <Field type="checkbox" name="document" class="form-check-input big-checkbox" id="po" value="PO"
                        v-model="document_value" />
                      <label style="font-size: 25px;" class="form-check-label" for="po">ใบเสนอราคา</label>
                    </div>

                  </center>
                  <!-- <div class="form-check form-check-inline">
                    <input class="form-check-input" name="document" type="checkbox" id="tor" value="TOR" v-model="document_value">
                    <label class="form-check-label" for="tor">TOR</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" name="document" type="checkbox" id="po" value="PO" v-model="document_value">
                    <label class="form-check-label" for="po">ใบเสนอราคา</label>
                  </div> -->
                  <!--end::Input group-->

                </div>

                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="document" />
                  </div>
                </div>

              </div>



            </div>
          </div>
          <div class="card-footer white">
            <div class="d-flex justify-center align-center">
              <button type="submit" class="button">ตกลง</button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import liff from "@line/liff";
import { defineComponent, ref, onMounted, inject } from "vue";
import { SET_REGISTER } from "@/store/modules/RegisterModel";
import useRegister from "@/service/api/register";
import { ErrorMessage, Field, Form } from "vee-validate";
import useSweetalert from "@/service/sweetalert2";
import * as Yup from "yup";
export default defineComponent({
  name: "Register",
  components: {
    ErrorMessage,
    Field,
    Form,
    Loading
  },
  setup() {

    const { Register } = useRegister();
    const interes_value = ref([]);
    const document_value = ref([]);
    let isLoading = ref(false);
    let color = ref(process.env.VUE_APP_COLOR);
    const imageUrl = ref([]);
    const { Sconfirm, SconfirmNc } = useSweetalert();
    const store = inject("store");
    const router = inject("router");
    let fn_status = ref(false);
    const data_register = store.getters.getRegister;
    const phoneRegExp =
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

    const register = Yup.object().shape({
      fn: Yup.string().required("กรุณาเลือก คำนำหน้า").label("คำนำหน้า"),
      fn_other: Yup.string()
        .when("fn", {
          is: (fn) => fn === "อื่นๆ",
          then: Yup.string().required("กรุณากรอก คำนำหน้า"),
        })
        .label("คำนำหน้า"),
      firstname: Yup.string().required("กรุณากรอก ชื่อ").label("ชื่อ"),
      lastname: Yup.string().required("กรุณากรอก นามสกุล").label("นามสกุล"),
      tel: Yup.string()
        .required("กรุณากรอกเบอร์โทรศัพท์")
        .matches(phoneRegExp, "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง")
        .min(10, "เบอร์โทรศัพท์ไม่ครบ")
        .max(10, "รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง"),
      agency_name: Yup.string().required("กรุณากรอก ชื่อ หน่วยงาน").label("ชื่อ"),
      interes_type: Yup.array().required().min(1, "กรุณาเลือก 1 ข้อ"),
    });

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const onSubmitRegister = (values) => {
      values.id_line = store.getters.getAccessToken.accesstoken;
      store.dispatch(SET_REGISTER, values);

      console.log(store.getters.getRegister);

      if (check_interes('interes_oa') == true) {

        if (!store.getters.getRegister.line_official_name || !store.getters.getRegister.imageUrl[0]) {
          SconfirmNc("กรุณากรอกข้อมูลให้ครบ", "error", "ตกลง").then();
        } else {
          register_submit();
        }
      } else if ((check_interes('interes_app') == true)) {
        if (!store.getters.getRegister.document[0]) {
          SconfirmNc("กรุณาเลือกเอกสาร 1 อย่าง", "error", "ตกลง").then();
        } else {
          register_submit();
        }
      } else {

        if (!store.getters.getRegister.line_official_name || !store.getters.getRegister.imageUrl[0] || !store.getters.getRegister.document[0]) {
          SconfirmNc("กรุณากรอกข้อมูลให้ครบ", "error", "ตกลง").then();
        } else {
          register_submit();
        }

      }

    };

    function register_submit() {

      Sconfirm("ยืนยัน การลงทะเบียน", "question").then(async (result) => {
        if (result.isConfirmed) {
          isLoading.value = true;

          await Register(store.getters.getRegister)
            .then((result) => {
              isLoading.value = false;
              if (result.data.status === "success") {
                SconfirmNc("ขอบคุณที่ท่านให้ความสนใจใช้บริการ เจ้าหน้าที่จะติดต่อกลับท่านเพื่อดำเนินการต่อไป", "success", "ตกลง").then(() => {

                  liff.ready.then(() => {
                    liff.closeWindow();
                  });

                });
              } else {
                SconfirmNc("ไม่มีสิทธิ์เข้าใช้งาน", "error", "ตกลง").then(
                  () => {
                    liff.ready.then(() => {
                      liff.closeWindow();
                    });
                  }
                );
              }
            })
            .catch((error) => {

              console.log(error);
            });
        }
      });

    }

    function check_interes(event) {
      var bool = interes_value.value.filter((item) => item === event);
      if (bool.length > 0) {
        return true;
      } else {
        return false;
      }
    }




    const selectImage = (e) => {
      if (e === 1) {
        document.getElementById("getFilecamera").click();
      } else {
        document.getElementById("getFile").click();
      }
    };

    const deleteImage = (val) => {
      imageUrl.value.splice(val, 1);
    };

    const pickFile = async (e) => {
      const files = e.target.files;
      if (files.length <= 1) {
        if (imageUrl.value.length < 1) {
          for (var i = 0; i < files.length; i++) {
            imageUrl.value.push(
              await reduce_image_file_size(await image_to_base64(files[i]))
            );
          }
        } else {
          SconfirmNc("แนบรูปได้ไม่เกิน 1 รูป", "error", "ตกลง");
        }
      } else {
        SconfirmNc("แนบรูปได้ไม่เกิน 1 รูป", "error", "ตกลง");
      }
    };


    async function reduce_image_file_size(
      base64Str,
      MAX_WIDTH = 1200,
      MAX_HEIGHT = 1200
    ) {
      let resized_base64 = await new Promise((resolve) => {
        let img = new Image();

        img.src = base64Str;
        img.onload = () => {
          let canvas = document.createElement("canvas");
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
          canvas.width = width;
          canvas.height = height;
          let ctx = canvas.getContext("2d");

          ctx.drawImage(img, 0, 0, width, height);

          resolve(canvas.toDataURL("image/jpeg")); // this will return base64 image results after resize
        };
      });
      return resized_base64;
    }

    async function image_to_base64(file) {
      let result_base64 = await new Promise((resolve) => {
        let fileReader = new FileReader();

        fileReader.onload = () => resolve(fileReader.result);
        fileReader.onerror = (error) => {
          console.log(error);
          alert("An Error occurred please try again, File might be corrupt");
        };
        fileReader.readAsDataURL(file);
      });

      return result_base64;
    }


    function checkFn(event) {
      if (event.target.value === "อื่นๆ") {
        fn_status.value = true;
      } else {
        fn_status.value = false;
      }
    }

    return {
      onSubmitRegister,
      register,
      checkFn,
      fn_status,
      data_register,
      selectImage,
      deleteImage,
      pickFile,
      color,
      check_interes,
      interes_value,
      isLoading,
      imageUrl,
      document_value
    };
  },
});
</script>

<style scoped>
.bdhd {
  border-bottom: 2px solid #dee2e6 !important;
}

.icon {
  width: 50px;
  padding: 5px;
}

.gender {
  padding: 30px 20px;
  border: 1px solid #dee2e6;
  border-radius: 10px;
}


input:checked+span {
  color: var(--color);
  border: 2px solid var(--color);
}

.button-upload {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: 100%;
  height: 80px !important;
}

.button-upload:hover,
.button-upload:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.button-upload:hover {
  transform: translateY(-1px);
}

.button-upload:active {
  background-color: #f0f0f1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}

.btn-close {
  font-size: 28px;
  display: block;
  float: right;
  color: #fff;
}

.no-padding {
  padding-left: 0;
  padding-right: 0;
}

.content {
  padding: 10%;
}

.images {
  width: 100%;
}

.xx {
  position: absolute;
  background-color: #b92735;
  color: white;
  right: 11px;
  padding: 6px;
}

label {
  font-size: 20px !important;
}

.form-control {
  font-size: 20px !important;
}

.big-checkbox {
  width: 1.5rem;
  height: 1.5rem;
  margin-top: 0.5rem;
  margin-right: 1rem
}
</style>
